import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Section = styled.section`
  width: 100%;
  height: 75vh;
  background: url("${props => props.image}") top center;
  background-size: cover;
  position: relative;
  padding: 0;
`

const Header = ({ image, title, subtitle, cta, ctaLink }) => {
  return (
    <>
      <Section image={image} id="hero" className="d-flex align-items-center">
        <div className="container position-relative text-center text-lg-start">
          <div className="row">
            <div className="col-lg-8">
              {title && <h1>{title}</h1>}
              {subtitle && <h2>{subtitle}</h2>}

              {cta && ctaLink && (
                <div className="btns">
                  <Link className="btn-menu" to={ctaLink}>
                    {cta}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Header
